import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const MembershipNew = () => {
  return (
    <div>
      <Helmet>
        <title>العضويات</title>
      
      </Helmet>
      <Header />
     
      <body style={{backgroundColor:"white"}}>

<HeroImg title="العضويات" />
      <main >
        
      <div class="container">
  <div class="row flex-items-xs-middle flex-items-xs-center">

   
    <div class="col-md-12 col-lg-6">
      <div class="cardMember ">
        <div class="cardMember-header">
          <h3>عضوية نديم</h3>
          <h3>1000 ريال/سنوياً	</h3>
          <hr />
        </div>
        <div class="cardMember-block">
         
          <ul class="list-group">
            التزامات العضوية : 
            <li class="list-group-item">1. التعاون مع الجمعية ومنسوبيها لتحقيق اهدافها</li>
            <li class="list-group-item">2. عدم القيام بما من شأنه أن يلحق ضرراً بالجمعية .</li>
            <li class="list-group-item"> 3.  لا يحق لعضو ( نديم ) حضور اجتماعات الجمعية العمومية ولا التصويت.</li>
          </ul>
          <ul class="list-group">
          مميزات العضوية :
            <li class="list-group-item">1. خصم 12%  على جميع برامج الجمعية الحضورية .</li>
            <li class="list-group-item">2. مقعدين مجانين في برامج الجمعية الافتراضية ( عن بعد ).</li>
            <li class="list-group-item"> 3. الاستفادة من خدمات المكتبة مجاناً ( القراءة – الاستعارة – الارشاد القرائي ).</li>
            <li class="list-group-item"> 4. خصم 50% على خدمة ( تأجير القاعة ) مرتين خلال مدة العضوية حسب لوائح الجمعية .</li>
            <li class="list-group-item"> 5. خصومات من متاجر ومقاهي متنوعة.</li>
            <li class="list-group-item"> 6. حضور اللقاءات التي تقيمها الجمعية للأعضاء .</li>


          </ul>
          <a href="https://store.qurtuba.org.sa/zvRrEad" class="btn btn-gradient mt-2">اشترك الآن</a>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6">
      <div class="cardMember ">
        <div class="cardMember-header">
          <h3 >عضوية جليس</h3>
          <h3>500 ريال/سنوياً		</h3>
          <hr/>
        </div>
        <div class="cardMember-block" >
         
          <ul class="list-group" style={{backgroundColor:"#F3F2F1"}}>
            التزامات العضوية : 
            <li class="list-group-item">1. التعاون مع الجمعية ومنسوبيها لتحقيق أهدافها</li>
            <li class="list-group-item">2. عدم القيام بما من شأنه أن يلحق ضرراً بالجمعية .</li>
            <li class="list-group-item"> 3.  لا يحق لعضو ( جليس ) حضور اجتماعات الجمعية العمومية ولا التصويت.</li>
          </ul>
          <ul class="list-group">
          مميزات العضوية :
            <li class="list-group-item">1. خصم 7%  على جميع برامج الجمعية الحضورية .</li>
            <li class="list-group-item">2. مقعد مجاني في برامج الجمعية الافتراضية ( عن بعد ).</li>
            <li class="list-group-item"> 3. الاستفادة من خدمات المكتبة مجاناً ( القراءة – الاستعارة – الارشاد القرائي ).</li>
            <li class="list-group-item"> 4. خصم 50% على خدمة ( تأجير القاعة ) مرة واحدة خلال مدة العضوية حسب لوائح الجمعية.</li>
            <li class="list-group-item"> 5. خصومات من متاجر ومقاهي متنوعة.</li>
            <li class="list-group-item"> 6. حضور اللقاءات التي تقيمها الجمعية للأعضاء .</li>


          </ul>
       
          <a href="https://store.qurtuba.org.sa/ZYrjeqB" class="btn btn-gradient mt-2">اشترك الآن</a>
        </div>
      </div>
    </div>

   



  </div>
</div>
      </main>
      <br/>
      <Footer />
      </body>
    </div>
  )
}
export default MembershipNew
