import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Administrations = () => {
  return (
    <div>
      <Helmet>
        <title>مجلس الإدارة</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title='مجلس الإدارة' title2='الدورة الثانية من 1444/2/8 إلى 1448/2/8 '  />
        <main>
          <div class="container x" style={{ direction: "rtl" }}>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>إقبال بنت عبدالله بن صالح العمران</b> -   رئيسة مجلس الإدارة  <br /> <h6> [الدورة الثانية]  </h6>
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> 
فاطمة بنت سعد بن نصار النصار
</b> - نائبة رئيسة مجلس الإدارة   <br /> <h6>  [الدورة الأولى]  </h6>
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>محمد بن صالح بن غرم الله الغامدي </b> -عضو مجلس الإدارة  <br /> <h6> [الدورة الثانية]  </h6>
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> مؤيد بن إبراهيم بن عبدالله الحدبان </b> - عضو مجلس الإدارة <br /> <h6> [الدورة الأولى]  </h6>
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>د.
                    فوزان بن عبدالرحمن بن حمد الفوزان 
                    </b> - عضو مجلس الإدارة <br/><h6> [الدورة الثانية] </h6>
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> عائشة بنت عبدالرحمن بن عبدالله العزاز </b> - عضو مجلس الإدارة <br /> <h6> [الدورة الثانية] </h6>
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>  خيرية بنت محمد بن عيسى الهاشم </b> - عضو مجلس الإدارة <br />  <h6> [الدورة الأولى] </h6>
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> نهاد بنت حمد بن علي العامر </b> - عضو مجلس الإدارة <br /> <h6> [الدورة الأولى] </h6>
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>د. عذاري عبدالرحمن مهناء اليابس </b> - عضو مجلس الإدارة <br /> <h6>[الدورة الأولى]  </h6>
                </p>
              </div>
            </div>
          </div>
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Administrations
