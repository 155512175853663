import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Volunteers = () => {
  return (
    <div>
      <Helmet>
        <title> فريق قنطرة التطوعي</title>

        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="فريق قنطرة التطوعي" />
        <main>
          <div class="container">
            <section>
              <div class="row" style={{ textAlign: "right" }}>
                <div class="col-md-12">
                  <div class="section-heading">
                    <p className="small p-2">
                      زاد الفكر ومجد الحضارات هو الكتاب، لذا كانت جمعية (قرطبة) للقراءة.. تطوعك معنا في فريق (قنطرة)
                      التطوعي سيفتح لنا ولك آفاقا نُحلق بها معًا لنكتشف ذواتنا بين أروقة الكتب ودهاليز المعرفة. سنطلعك
                      على اقسام التطوع وأبرز المهام فيها؛ ليتسنى لك اختيار ما تجود به نفسك وتطمح من خلاله في صنع هذا
                      الأثر بفكرك وإبداعك.
                    </p>
                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdBbWYzZEop29k3bome9nhrsfkuM5JM0nBP_zT8eS6YOcVnkw/viewform"
                        class="blog-slider__button med-5"
                        style={{
                          width: "auto",
                          padding: "10px 30px",
                          textDecoration: "none",
                          color: "white",
                          cursor: "pointer",
                        }}
                        data-toggle="tooltip"
                        title="استمارة التسجيل في فريق (قنطرة) التطوعي"
                      >
                        تطوع معنا
                      </a>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Volunteers
