import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const disclosure  = () => {
  return (
    <div>
      <Helmet>
        <title> الإفصاح</title>

        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="الإفصاح" />
        <main>
      
        <br />
         

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/disclosure1.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                   نموذج الإفصاح وعدم تعارض المصالح
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>

              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/disclosure2.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                    تعهد عدم تعارض المصالح
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>




              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/disclosure3.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                      الأستتثمار و التملك
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>

        

</div>
</div>



  
        </main>
        <br />
        <br />
        <Footer />
      </body>
    </div>
  )
}
export default disclosure 
