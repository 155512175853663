import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Header from "./Header2"
import Footer from "./Footer"
import HeroImg from "./HeroImg"

const Design = () => {
  return (
    <div>
      <Helmet>
        <title>الأهداف والبناء الاستراتيجي  </title>
        <script type="text/javascript" src="/./assets/js/scriptCustom.js"></script>
        <script type="text/javascript" src="https://code.jquery.com/jquery-2.2.4.min.js"></script>
      </Helmet>
      <Header />
      <body>
        <HeroImg title= "البناء الاستراتيجي " />
        <main className="home-wrapper" style={{ backgroundColor: "white" }}>
          <section>
            <div className="separator"></div>

            <div className="container-fluid">
            
            
            </div>
          </section>
          <section style={{ padding: "0px 0px" }}>
            <div class="row" style={{ position: "relative" }}>
              <div class="container">
                <div
                  class="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                  style={{ direction: "rtl" }}
                >
                   <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                         الأهداف
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      class="panel-collapse collapse in"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div class="panel-body">
                        <ul class="collapse panel-collapse in">
                          <li>  نشر ثقافة القراءة الهادفة.</li>
                          <li>تكوين نماذج مثقفة معتزة بقيمها وهويتها.</li>
                          <li>   زيادة إيرادات الجمعية من مصادر متنوعة وشبه ثابتة.</li>
                          <li>    تحسين الكفاءة المالية وترشيد الإنفاق.</li>
                          <li>    تصميم برامج وبيئات نوعية ومبتكرة وجاذبة للفتيات.</li>
                          <li>    بناء الشراكات التي تخدم أهداف الجمعية.</li>
                          <li>     تسويق برامج وأنشطة الجمعية.</li>
                          <li>    استثمار التحول الرقمي وأتمتة عمليات الجمعية.</li>
                          <li>    استثمار المتطوعين في أعمال الجمعية.</li>
                          <li>     تأهيل وتدريب العاملين في الجمعية.</li>
                          <li>      بناء أنظمة ولوائح الجمعية.</li>
                          <li>    بناء بيئة عمل جاذبة.</li>

                        </ul>
                      </div>
                    </div>
                  </div>
                   <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingFive">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          البعد المالي
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseFive"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingFive"
                    >
                     <div class="panel-body">
                        ضمان استدامة اقــتــــصــــاديـــــة (مـــصـــادر دخـــل ثابتة ومتـنوعـة وقـابـلـة للـنمو)
                        <ul class="collapse panel-collapse in">
                          <li> زيادة إيرادات الجمعية من مصادر متنوعة وشبه ثابتة.</li>
                          <li> تحسين الكفاءة المالية وترشيد الإنفاق .</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                 
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingTwo">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          العملاء
                        </a>
                      </h4>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                      <div class="panel-body">
                        اجــتــذاب العـمـلاء وكــسـب رضـاهــم وتلبية احتياجاتهم ورغباتهم
                        <ul class="collapse panel-collapse in">
                          <li>نشر ثقافة القراءة الموجهة.</li>
                          <li> تكوين نماذج مثقفة ومعتزة بقيمها وهويتها .</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          العمليات الداخلية
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseThree"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingThree"
                    >
                      <div class="panel-body">
                        الإجراءات والوسائل الأكــثـــر تــحــقـيــقـــاً لـــرضــا الـــعــــمــلاءوالإستدامة المالية
                        وتــحــقـــيـــق رضـــــــا أصـحـاب المـصلـحــة
                        <ul class="collapse panel-collapse in">
                          <li>تصميم برامج وبيئات نوعية ومبتكرة وجاذبة للفتيات.</li>
                          <li>بناءالشراكات التي تخدم أهداف الجمعية.</li>
                          <li>تسويق برامج وأنشطة الجمعية.</li>
                          <li>استثمار التحول الرقمي وأتمتة عمليات الجمعية.</li>
                          <li>استثمار المتطوعين في أعمال الجمعية.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingFour">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          التعلم والنمو
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseFour"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingFour"
                    >
                      <div class="panel-body">
                        الـثقافة المؤسسية والإدارية والتنظيمية ومـعـدلات الـتـدريــب لـلـعـامـلـيـن
                        <ul class="collapse panel-collapse in">
                          <li>بناء أنظمة ولوائح الجمعية.</li>
                          <li>تأهيل وتدريب العاملين في الجمعية.</li>
                          <li>بناء بيئة عمل جاذبة.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator"></div>
          </section>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Design
