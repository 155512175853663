
 export const files=[
   // {"id":1, "title":"اللائحة الأساسية المعتمدة من الوزارة", "url":"/files/main.pdf", "created_at":"2020-12-15 21:04:21"},
  //  {"id":2, "title":"آلية إدارة المتطوعين", "url":"/files/q1.pdf", "created_at":"2020-12-15 21:04:21"},
   // {"id":3, "title":"نظام الرقابة المالية", "url":"/files/q2.pdf", "created_at":"2020-12-15 21:05:28"},
   // {"id":5, "title":"لائحة الصلاحيات", "url":"/files/q3.pdf", "created_at":"2020-12-15 21:04:21"},
   // {"id":6, "title":"الميثاق الأخلاقي للعاملين في القطاع غير الربحي", "url":"/files/q5.pdf", "created_at":"2020-12-15 21:04:21"},
   // {"id":7, "title":"اللائحة المالية", "url":"/files/q6.pdf", "created_at":"2020-12-15 21:04:21"},
   // {"id":8, "title":"لائحة الموارد البشرية", "url":"/files/q8.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":9, "title":"لائحة مجلس الإدارة المعتمدة", "url":"/files/q9.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":10, "title":"لائحة حفظ الوثائق والمكاتبات والسجلات الخاصة", "url":"/files/q4.pdf", "created_at":"2020-12-15 21:04:21"},

     
    {"id":11, "title":"نظام الرقابة الداخلية", "url":"/files/q10.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":12, "title":"لائحة الموارد البشرية ","url":"/files/q11.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":13, "title":" لائحة المشتريات والتعاقدات","url":"/files/q012.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":14, "title":"لائحة الصرف للأنشطة والبرامج","url":"/files/q13.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":15, "title":"صلاحيات مجلس الإدارة", "url":"/files/q014.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":16, "title":"الميثاق الأخلاقي", "url":"/files/q15.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":17, "title":"اللائحة المالية","url":"/files/q16.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":18, "title":"اللائحة الاساسية الجديدة لجمعية قرطبة","url":"/files/q17.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":19, "title":"الدليل التعريفي لمجلس الإدارة","url":"/files/q18.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":20, "title":"الدليل الاجرائي للرقابة الداخلية", "url":"/files/q19.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":21, "title":"الدليل الاجرائي لتجنب تنبيه العميل أو المتبرع المشتبه به","url":"/files/q20.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":22, "title":"آلية قبول أعضاء الجمعية العمومية","url":"/files/q21.pdf", "created_at":"2024-10-9 11:09:14"},
    {"id":23, "title":"آلية استرداد التبرع للمتبرع", "url":"/files/q22.pdf", "created_at":"2024-10-9 11:09:14"},

];
