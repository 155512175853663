import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const StandingCommittess = () => {
  return (
    <div>
      <Helmet>
        <title>اللجان الدائمة </title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="اللجان الدائمة" />
        <main>
          <br />
          <br />
          <div class="container x" style={{ direction: "rtl" }}>
            <div class="Title">
              <b>اللجان الفرعية المساندة</b>
            </div>
            <div>
              <p className="text-right">
                لجان فرعية متخصصة في إطار المجلس؛ لتنظيم عمله، ورسم سياسات الجمعية، ودعم الإدارة التنفيذية في أداء
                مهامها، وتحقيق رسالتها المنشودة.
              </p>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar" style={{ paddingLeft: "5%" }}>
                  <div class="Title">
                    <b>لجنة البرامج والكتب والمشاركين</b>
                  </div>
                  <div>
                    <p>
                      تهدف اللجنة إلى رسم سياسات البرامج ووضع الخطوط العريضة والضوابط والمعايير التي تحكم تخطيط البرامج
                      واختيار الكتب والمشاركين، سواء للمكتبة أو للبرامج، ثم رفعها للمجلس لاعتمادها، مع تقديم الدعم
                      للفريق التنفيذي.
                    </p>
                  </div>
                  {/* <div class="Points">
                    <b>أهدافها: </b>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>
                        {" "}
                        تمثيل منظومة مهمة في اتخاذ القرارات المتعلقة بالبرامج والمشاريع بالجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        {" "}
                        تحقيق رؤية الجمعية وتحديد أولوية الاحتياج للمستفيد.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        مواجهة التحديات والتعامل مع المتغيرات والاستغلال الامثل للفرص والموارد.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        اصدار القرارات بناء على دراسات وإحصائيات يقوم بها فريق عمل اللجنة.
                        <br />
                      </li>
                    </ul>
                  </div> */}
                  <div class="Points">

                  <b>اختصاصات اللجنة: </b></div>
                    <br />
                  
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}> المشاريع والبرامج.</li>
                      <li style={{ listStyleType: "circle" }}>الكتب.</li>
                      <li style={{ listStyleType: "circle" }}>المشاركون.</li>
                    </ul>
                 
                  <div class="Points">
                    <b>الأعضاء : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}> أ. فاطمة النصار (رئيسة)</li>
                      <li style={{ listStyleType: "circle" }}>د.عذاري اليابس</li>
                      <li style={{ listStyleType: "circle" }}>د.انعام الدليجان</li>
                      <li style={{ listStyleType: "circle" }}>أ. أمل الجعفري</li>
                    </ul>
                 
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar" style={{ paddingLeft: "5%" }}>
                  <div class="Title">
                    <b>لجنة الموارد المالية والتسويق والعلاقات العامة</b>
                  </div>
                  <div>
                    <p>
                      تهدف لجنة الموارد المالية والتسويق والعلاقات إلى إيجاد مصادر مالية تشارك فيها عمليات التسويق
                      والعلاقات.
                    </p>
                  </div>
                  {/* <div class="Points">
                    <b>أهدافها: </b>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>رفع نسبة النمو المالي في الجمعية.</li>
                      <li style={{ listStyleType: "circle" }}>دراسة السوق المحلي وفرص الاستثمار.</li>
                      <li style={{ listStyleType: "circle" }}>تحقيق عوائد مجزية من الاستثمارات المالية.</li>
                      <li style={{ listStyleType: "circle" }}>تحقيق الاستدامة المالية للجمعية.</li>
                    </ul>
                  </div> */}
                  <div class="Points">
                    <b>اختصاصات اللجنة : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>الموارد المالية.</li>
                      <li style={{ listStyleType: "circle" }}>التسويق.</li>
                      <li style={{ listStyleType: "circle" }}>العلاقات العامة.</li>
                    </ul>
                  
                  <div class="Points">
                    <b> الأعضاء : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}> أ. نهاد حمد العامر (رئيس)</li>
                      <li style={{ listStyleType: "circle" }}> أ. عائشة عبدالرحمن عزاز (عضو)</li>
                    </ul>
                 
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar" style={{ paddingLeft: "5%" }}>
                  <div class="Title">
                    <b>اللجنة المالية</b>
                  </div>
                  <div>
                    <p>
                      تهدف اللجنة المالية إلى ضبط التدفقات والمصروفات المالية للجمعية، والقيام بعمليات المراقبة
                      والمتابعة والتحليل والإشراف اللازمة، وإعداد التقارير المالية وعرضها على مجلس الإدارة والجمعية
                      العمومية.
                    </p>
                  </div>
                  <div class="Points">
                    <b>اختصاصات اللجنة : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>التدفقات والمصروفات النقدية.</li>
                      <li style={{ listStyleType: "circle" }}> التقارير المالية.</li>
                    </ul>
                
                  <div class="Points">
                    <b> الأعضاء : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>أ.محمد صالح الغامدي (رئيس)</li>
                      <li style={{ listStyleType: "circle" }}> أ. مؤيد ابراهيم الحدبان (عضو)</li>
                      <li style={{ listStyleType: "circle" }}> أ. وليد الدريهم (عضو)</li>
                    </ul>
               
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar" style={{ paddingLeft: "5%" }}>
                  <div class="Title">
                    <b>لجنة التطوير والابتكار</b>
                  </div>
                  <div>
                    <p>
                      تهدف لجنة التطوير والابتكار إلى تطوير أداء وبرامج ومنتجات الجمعية، وذلك بتطوير أداء موظفي وموظفات
                      الجمعية، وعقد لقاءات دورية لتطوير برامجها القائمة، وابتكار وصياغة برامج جديدة.
                    </p>
                  </div>
                  {/* <div class="Points">
                    <b>أهدافها: </b>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>
                        مساعدة الجمعية على تحديد دقيق لأهدافها والأدوار ومؤشرات قياس الأداء
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        {" "}
                        ايجاد فهم مشترك داخل الجمعية حول الاهداف والانجازات التي تسعى لتحقيقها. <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        تطوير الأنظمة الإدارية المعمول بها بالجمعية.
                        <br />
                      </li>
                    </ul>
                  </div> */}
                  <div class="Points">
                    <b>اختصاصات اللجنة : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>التطوير الإداري.</li>
                      <li style={{ listStyleType: "circle" }}>
                        الابتكار والأفكار الإبداعية التي تسهم في تحقيق أهداف الجمعية.
                      </li>
                    </ul>
                  
                  <div class="Points">
                    <b> الأعضاء : </b></div>
                    <br />
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>د. فوزان عبدالرحمن الفوزان (رئيس)</li>
                      <li style={{ listStyleType: "circle" }}>أ.خيرية محمد الهاشم (عضو)</li>
                      <li style={{ listStyleType: "circle" }}>أ. نهى حميد المستادي (عضو)</li>
                    </ul>
                 
                </p>
              </div>
            </div>
          </div>
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default StandingCommittess
