import React, { useState, useEffect } from "react"
import Axios from "axios"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import { files } from "./data"
import HeroImg from "./HeroImg"

var moment = require("moment-hijri")

const GovFiles = () => {
  return (
    <div>
      <Helmet>
        <titl> "اللوائح" </titl>
    
      </Helmet>
      <Header />
      <body>
        <HeroImg title= "اللوائح  " style={{ width: "100%" }} />

        <main>
          <br />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              {files.map(item => {
                return (
                  <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                    <a href={"../assets/downloadFiles" + item.url} target="_blank">
                      <div
                        className="card-body gallery-img"
                        style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
                      ></div>
                      <div className="card-footer text-left">
                        <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                          {item.title}
                        </p>
                        <p className="card-text s16 detail-card xsmall">
                          عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        </p>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </main>
        <br />
        <Footer />
      </body>
    </div>
  )
}

export default GovFiles
