const DataMeet = 
[ 
  {
   "category": "عام 2024",
   "id": 1,
   "list": [{
    "link": "../assets/downloadFiles/files/meeting2024.pdf",
    "title": "اجتماع الجمعية العمومية غير العادي الأول لعام 2024",
     
   }]
},
{
 "category": "عام 2023",
 "id": 2,
 "list": [
    {
        "link": "../assets/downloadFiles/files/meeting2023-2.pdf",
        "title": "اجتماع الجمعية العمومية الثاني - الغير عادي  بتاريخ 1_5_1445 هـ" ,
        
    },
    {
        "link": "../assets/downloadFiles/files/meeting2023-3.pdf",
        "title": "اجتماع الجمعية العمومية الرابع - يوم السبت 10 - 7 - 1445",
        
    }
]
},
{
    "category":  "عام 2022",
    "id": 4,
    "list": [{
        "link": "../assets/downloadFiles/files/meeting2022Nor.pdf",
        "title": "اجتماع الجمعية العمومية (عادي) - انتخابات 2022",
         
    },
    {
        "link": "../assets/downloadFiles/files/meeting2022Not.pdf",
        "title": "اجتماع الجمعية العمومية (غير عادي) - 2022",
         
    }
]
   },
{
  "category":  "عام 2021",
  "id": 3,
  "list": [{
      "link":  "../assets/downloadFiles/files/meeting2021.pdf",
      "title":  "اجتماع الجمعية العمومية الثاني - يوم الأربعاء 1 - 2 - 1443",
       
  }]
 },
 {
    "category":  "عام 2019-2020",
    "id": 5,
    "list": [{
        "link":  "../assets/downloadFiles/files/1.pdf",
        "title":  "اجتماع الجمعية العمومية الأول لعام 2019-2020",
         
    }]
   },
   {
    "category":  "أخرى",
    "id": 6,
    "list": [{
        "link":  "../assets/downloadFiles/files/other1.pdf",
        "title":  "فرز الأصوات لمجلس الادارة الثاني ",
         
    },{
        "link":  "../assets/downloadFiles/files/other2.pdf",
        "title":  "تنفيذ قرارات-الجمعية-العمومية",
         
    }]
   }
]
export default DataMeet
