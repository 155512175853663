import React from "react";
import Header from "./Header2";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import HeroImg from "./HeroImg";

const Executive = () => {
  return (
    <div>
      <Helmet>
        <title> الإدارة التنفيذية</title>
        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="الإدارة التنفيذية" />








        <main className="container">
    <div class="row row-cols-1 row-cols-md-2 g-4">
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="https://static.vecteezy.com/system/resources/previews/022/086/466/original/arab-woman-face-covered-with-hijab-muslim-woman-muslim-girl-avatar-avatar-icon-in-flat-style-smiling-girl-in-a-scarf-isolated-illustration-vector.jpg"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-3">نوال محمد الضويان<small className="small">المدير التنفيذي</small></h2>
           
               <h6>للتواصل </h6>  
                  <div class="d-flex flex-column xsmall2" >
  
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: ceo@qurtuba.org.sa 
  </div>
  
</div>
                 
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51">قسم البرامج</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
  <div style={{fontWeight:400}}>
  
                      واتس اب: 0568303369
  </div>
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: Project1@qurtuba.org.sa
  </div>
  <div style={{fontWeight:400}}>

البريد الإلكتروني: Project2@qurtuba.org.sa
</div>
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                    src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4">قسم التطوع</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
  <div style={{fontWeight:400}}>
  
                      واتس اب: 0568331359
  </div>
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: volunteering@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4">قسم تنمية الموارد المالية</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
  <div style={{fontWeight:400}}>
  
                      واتس اب: 0561931334
  </div>
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: finance@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4">قسم العلاقات العامة</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
  <div style={{fontWeight:400}}>
  
                      واتس اب: 0546530039
  </div>
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: info@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4"> قسم الموارد البشرية</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
  
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: hr@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4">قسم التسويق</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
 
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: marketing@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4">قسم الإعلام</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
 
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: media@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4">قسم المكتبة</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
 
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: library@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
      <div class="col">
      <div className="card profile-card-3">
                <div className="background-block">
                  <img
                    src="assets/Images/IMG_5516.jpg"
                    alt="profile-sample1"
                    className="background"
                  />
                </div>
                <div className="profile-thumb-block">
                  <img
                     src="/assets/Images/user.png"
                    alt="profile-image"
                    className="profile"
                  />
                </div>
                <div className="card-content">
                  <h2 className="med-51 mb-4" >قسم  المحاسبة</h2>
                  <br/>
                  <h6>للتواصل </h6>  

                  <div class="d-flex flex-column xsmall2" >
 
  <div style={{fontWeight:400}}>

                      البريد الإلكتروني: accountant@qurtuba.org.sa
  </div>
 
  
</div>
                </div>
              </div>
      </div>
    </div>
  </main>


        <Footer />
      </body>
    </div>
  );
};

export default Executive;
