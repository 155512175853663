import React from "react"; 
import { Helmet } from "react-helmet"; 
import Header from "./Header2"; 
import Footer from "./Footer"; 
import HeroImg from "./HeroImg"; 

const ContactUs = () => { 
  return ( 
    <div> 
      <Helmet> 
        <title>تواصل معنا</title> 
       
        <link 
          rel="stylesheet" 
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" 
        />
      </Helmet> 
      <Header /> 

      <body> 
         
        <HeroImg title="تواصل معنا" /> 

        <main> 
          <div className="container"> 
            <section className="py-3 py-md-5"> 
              <div className="container"> 
                <div className="row justify-content-md-center"> 
                  <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6"> 
                    <h1 
                      style={{ 
                        textAlign: "center", 
                        marginBottom: "30px", 
                        fontSize: "22px", 
                        
                        fontWeight: "bold", 
                        lineHeight: "1.5", 
                      }}
                    > 
                      في حال وجود استفسارات يمكنكم التواصل معنا عبر الروابط التالية 
                    </h1> 
                  </div> 
                </div> 
              </div> 

 
              <div className="container"> 
                <div className="row justify-content-lg-center"> 
                  <div className="col-12 col-lg-9"> 
                    <div className="bg-white border rounded shadow-sm overflow-hidden p-4"> 
                       
                      {/* البريد الإلكتروني */} 
                      <div className="contact-box" style={{ marginBottom: "20px", textAlign: "center" }}> 
                        <a href="mailto:ceo@qurtuba.org.sa" style={{ textDecoration: "none" }}> 
                          <div className="box" style={{ backgroundColor: "#f8f9fa", padding: "15px", borderRadius: "8px" , color:"#843534"}}> 
                            <h5>

                              البريد الإلكتروني
                            </h5> 
                            <p>ceo@qurtuba.org.sa</p> 
                          </div> 
                        </a> 
                      </div> 

                      {/* الهاتف */} 
                      <div className="contact-box" style={{ marginBottom: "20px", textAlign: "center" }}> 
                        <a href="tel:0138333998" style={{ textDecoration: "none" }}> 
                          <div className="box" style={{ backgroundColor: "#f8f9fa", padding: "15px", borderRadius: "8px" , color:"#843534"}}> 
                            <h5>
                              
                              الهاتف
                            </h5> 
                            <p>0138333998</p> 
                          </div> 
                        </a> 
                      </div> 

                      {/* واتس اب */} 
                      <div className="contact-box" style={{ textAlign: "center" }}> 
                        <a href="https://wa.me/0546530039" style={{ textDecoration: "none" }}> 
                          <div className="box" style={{ backgroundColor: "#f8f9fa", padding: "15px", borderRadius: "8px", color:"#843534" }}> 
                            <h5>
                             
                              واتس اب
                            </h5> 
                            <p>0546530039</p> 
                          </div> 
                        </a> 
                      </div> 
                      </div> 
                  </div> 
                </div> 
              </div> 
            </section> 
          </div> 
        </main> 
        <Footer /> 
      </body> 
    </div> 
  ); 
}; 

export default ContactUs;
