export const policy=[
   // {"id":0, "title":"سياسة الاحتفاظ بالوثائق و اتلافها", "url":"/policy/policy1.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":2, "title":"سياسة الابلاغ عن المخالفات و حماية مقدمي البلاغات", "url":"/policy/policy2.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":3, "title":"سياسة تنظيم العلاقة مع المستفيدات", "url":"/policy/policy3.pdf", "created_at":"2020-12-15 21:05:28"},
    //{"id":4, "title":"سياسة الاشتباه بعمليات غسيل الأموال و جرائم تمويل الارهاب", "url":"/policy/policy4.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":5, "title":"سياسة جمع التبرعات", "url":"/policy/policy5.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":6, "title":"سياسة الوقاية من عمليات غسيل الأموال و جرائم تمويل الارهاب", "url":"/policy/policy6.pdf", "created_at":"2020-12-15 21:04:21"},
    //{"id":7, "title":"سياسة خصوصية البيانات", "url":"/policy/policy7.pdf", "created_at":"2020-12-15 214:21"},
    //{"id":8, "title":"سياسة تعارض المصالح", "url":"/policy/policy8.pdf", "created_at":"2020-12-15 21:04:21"},


    {"id":9, "title":"قائمة مخاطر تمويل الارهاب", "url":"/policy/policy9.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":10, "title":"سياسة مكافحة غسل الأموال وتمويل الإرهاب", "url":"/policy/policy10.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":11, "title":"سياسة خصوصية البيانات", "url":"/policy/policy11.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":12, "title":"سياسة جمع التبرعات", "url":"/policy/policy12.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":13, "title":"سياسة توجيه مبلغ التبرع الى مشروع آخر", "url":"/policy/policy13.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":14, "title":"سياسة تنظيم العلاقة مع المستفيدين", "url":"/policy/policy14.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":15, "title":"سياسة الوقاية من عمليات غسيل الأموال وجرائم تمويل الإرهاب", "url":"/policy/policy15.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":16, "title":"سياسة المقبوضات", "url":"/policy/policy16.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":17, "title":"سياسة الاشتباه بعمليات غسل الأموال وجرائم تمويل الإرهاب", "url":"/policy/policy17.pdf", "created_at":"2024-10-09 12:16:21"},

    {"id":18, "title":"سياسة الاستثمار", "url":"/policy/policy18.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":19, "title":"سياسة الاحتفاظ بالوثائق وإتلافها", "url":"/policy/policy19.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":20, "title":"سياسة الإبلاغ عن المخالفات وحماية مقدمي البلاغات", "url":"/policy/policy20.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":21, "title":"سياسة إدارة المتطوعين", "url":"/policy/policy21.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":22, "title":"سياسة - تعارض المصالح", "url":"/policy/policy22.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":23, "title":"السياسات والأجراءات والضوابط المتعلقه بمكافحة جرائم تمويل الإرهاب وغسل الأموال", "url":"/policy/policy23.pdf", "created_at":"2024-10-09 12:16:21"},
    {"id":24, "title":"السياسات والاجراءات المالية", "url":"/policy/policy24.pdf", "created_at":"2024-10-09 12:16:21"},
];